import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import Config from 'Data';

import { SEO } from 'Components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Content,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FeaturedSection,
  FeaturedEvent,
  FirstSection,
  FlatSection,
  FooterSection,
  Wrapper,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  EventsGrid,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

class Terms extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    const graphQuery = data.index;
    const pageData = graphQuery.terms.childMarkdownRemark.frontmatter;
    const pageBody = graphQuery.terms.childMarkdownRemark.html;

    return (
      <Layout>
        <Helmet
          title={`Terms of use | ${Config.brandBusiness} by ${
            Config.contact.name
          }`}
        />
        <SEO
          pathname="/terms"
          title={`Terms of use | ${Config.brandBusiness} by ${
            Config.contact.name
          }`}
        />

        <Gap />

        <FullWrapper>
          <Content>
            <h1>Terms of use</h1>
          </Content>
        </FullWrapper>
        <Gap />
      </Layout>
    );
  }
}

export default Terms;

export const query = graphql`
  query TermsQuery {
    index: privacyJson {
      terms {
        childMarkdownRemark {
          id
          html
          frontmatter {
            path
            title
          }
        }
      }
    }
  }
`;
